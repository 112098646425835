.advertising-billing-container {
  display: block;
  max-width: 1000px;
  margin: auto;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background: white;
}

.advertising-billing-container .page-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  background: #712d93;
  background-blend-mode: normal, overlay, normal;
  padding: var(--padding-32px, 32px) 0px;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: Open Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  /* 41.6px */
}

.advertising-billing-container .billing-description {
  display: flex;
  max-width: 100%;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  flex: 4;
}

.advertising-billing-container .main-box {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.advertising-billing-container .description-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.advertising-billing-container .description-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  color: #000;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  /* 20.8px */
}

.advertising-billing-container .description-body {
  position: relative;
}

.advertising-billing-container .circle-container {
  width: 314px;
  height: auto;
  max-width: 100%;
  min-height: 100%;
  /* position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0; */
}

.advertising-billing-container .description-center {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.advertising-billing-container .description-center p {
  margin: 0;
  color: #000;
  text-align: center;
  font-family: ' Open Sans';
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 80%;
  /* 52px */
}

.advertising-billing-container .description-center p.dollar-28 {
  color: #712d93;
  font-family: 'Open Sans';
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.advertising-billing-container .description-center p.per-year {
  color: #000;
  font-family: 'Open Sans';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.advertising-billing-container .label {
  align-self: stretch;
  color: #000;
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  /* 20.8px */
}

.advertising-billing-container .form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 100%;
  gap: var(--padding-16px, 16px);
}

.advertising-billing-container .form-control {
  display: flex;
  flex-direction: column;
  gap: var(--padding-16px, 16px);
  width: 100%;
}

.advertising-billing-container .plan {
  display: flex;
  padding: var(--padding-24px, 24px) var(--padding-16px, 16px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--padding-8px, 8px);
  align-self: stretch;
  border-radius: var(--padding-4px, 4px);
  border: 1px solid #712d93;
  background: #D7E7F6;
}

.advertising-billing-container .per-year {
  color: #000;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  margin: 0;
  /* 26px */
}

.advertising-billing-container .tutor-plan {
  margin: 0;
  color: #000;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  /* 20.8px */
}

.advertising-billing-container .accept {
  color: #000;
  text-align: center;
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  /* 20.8px */
}

.advertising-billing-container .term {
  position: relative;
  color: #712d93;
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  z-index: 990;
  cursor: pointer;
}

.advertising-billing-container .term:hover {
  text-decoration: underline;
}

@media screen and (max-width: 825px) {
  .advertising-billing-container .main-box {
    flex-direction: column;
  }
}

@media screen and (max-width: 525px) {
  .advertising-billing-container .main-box {
    padding: 16px;
  }
}