.saved-cards-container {
    padding: 20px;
}

h2 {
    margin-bottom: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th,
td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

th {
    background-color: #f2f2f2;
}

button {
    padding: 8px 12px;
    margin-left: 10px;
    cursor: pointer;
}

.add-card-button {
    margin-top: 20px;
}

form.add-card-form input {
    padding: 10px 20px;
    position: relative;
    width: 100%;
    text-align: center;
    border-radius: 3px;
    border-color: #d3d3d3;
    border: solid 1px #d3d3d3;
    font-size: 18px;
    background: #eeeeee78;
    margin-bottom: 15px;
}

.card-number-row {
    width: 100%;
    margin-bottom: 16px;
}

.expiry-cvc-row {
    display: flex;
    gap: 10px;
}

.expiry-cvc-row>* {
    flex: 1;
}

.card-input,
.expiry-cvc-row {
    margin: 15px 0;
}

.card-input input,
.expiry-cvc-row>* {
    width: 100%;
    padding: 10px;
    font-size: 16px;
}

.expiry-cvc-row {
    display: flex;
    gap: 10px;
}

.expiry-cvc-row>* {
    flex: 1;
}

#card-element {
    border: 1px solid currentColor;
    border-radius: 4px;
    height: 50px;
    background: #f7f7f7;
    margin-bottom: 20px;
    padding-top: 10px;
    font-size: 27px;
    padding: 16px;
}

.billing-container .addcard-btn {
    padding: 0.75rem 1.5rem;

    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    margin-top: 15px;
    margin-right: 0;
    margin-left: 0;
}