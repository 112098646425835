.hide {
  display: none;
}

.uploadedimages {
  margin-bottom: 10px;
}

.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 0rem;
  border: 1px solid whitesmoke;
  border-radius: 10px;
  width: 100%;
  max-width: 800px !important;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); */
}

.remove-file-icon:hover {
  color: #712d93;
}

.uploadedimages:hover svg {
  color: white;
  background: #712d93;
  border-radius: 50%;
}

.uploadedimages svg {
  cursor: pointer;
}

img.uploaded_image {
  margin-bottom: 20px;
}

.contact-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.des {
  padding-bottom: 16px;
}

.contact-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4.5rem 1rem 0rem;
}

.contact-container .page-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-container .page-heading h1 {
  margin-bottom: 0;
}

.Childform {
  width: 60%;
  background-color: rgb(250, 249, 249);
  padding: 30px;
}

.form-top {
  height: 70px;
}

.form-top>img {
  height: 50px;
}

p {
  font-size: 17px;
  font-family: "Open Sans";
}

.mt20 {
  margin-top: 20px;
}

#social-link-label {
  padding-left: 8px;
}

#by-label {
  padding-left: 8px;
}

#name-of-course-label {
  padding-left: 8px;
}

input {
  margin-top: 5px;
  padding: 5px;
  margin-right: 10px;
  line-height: 1.2em;
}

.inp250 {
  min-width: 505px;
}

.red {
  color: red;
}

.input-container {
  display: flex;
  flex-direction: column;
}

.SubmitBtn {
  margin: 0px;
}

.submitbtn-inside {
  float: right;
  margin-top: 140px;
}

.childheading {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}

.contact-link-style {
  color: #712d93 !important;
}

.contact-link-style:hover {
  color: #2baaff !important;
}

.submit-contact {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.submit-contact>button {
  flex: 0;
  padding-right: 1rem;
  padding-left: 1rem;
  min-width: 100px !important;
}

@media screen and (max-width: 525px) {
  .submit-contact>button {
    flex: 1;
  }
}

.ck-editor__editable_inline {
  min-height: 20vh;
}

.ck-powered-by {
  display: none;
}