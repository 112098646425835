.profile-detail-section {
    margin-bottom: 0.75rem;
    display: flex;
    justify-content: space-between;
}

.profile-cat {
    font-size: 18px;
    color: #000000;
    margin-right: 5rem;
    cursor: pointer;
}

.subjecttab {
    padding-bottom: 100px;
}

.button-boxs {
    text-align: right;
    display: block;
}

.profile-cat-done {
    font-size: 18px;
    font-weight: bold;
    color: #00AB55;
    float: right;
}

.profile-cat-not-done {
    font-size: 18px;
    font-weight: bold;
    color: #da1e28;
    float: right;
}

.boxy h5,
.boxy p {
    color: white;
}

.boxy.main h5,
.boxy.main p {
    color: black;
}

.profile-cat:hover {
    text-decoration: underline;
}

form.boxy.MuiBox-root.css-0 {
    max-width: 700px;
}

.boxy h4 {
    margin-bottom: 30px;
    margin-top: 30px;
}

.rccs__card.rccs__card--unknown {
    margin-bottom: 30px;
}



.stripe-input-container>div .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2.css-mhc70k-MuiGrid-root {
    /* max-width: 521px; */
    position: relative;
    left: 8px;
    padding: 6px 9px;
}

.input-names {
    margin: 0;
    padding: 0;
}

.input-names-cont {
    max-width: 400px
}

.memberoptions {
    font-size: 22px !important;
    padding: 10px !important;
}

@media screen and (max-width: 899px) {

    .profile-detail-section {
        flex-direction: column;
    }
}