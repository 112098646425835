.billing-container {
  padding: 2rem;
  width: calc(100% - 220px);
  margin-left: 0;
  max-width: 900px;
  margin: auto;
}

.billing-menu.notfixed {
  position: relative !important;
  top: 48px;
}

.billing-content.full-width {
  width: 100% !important;
  max-width: 100% !important;
}

.billing-container .form-control {
  margin-bottom: 1.5rem;
}

.billing-container .form-control-inline {
  display: flex;
  gap: 1rem;
}

.billing-container .form-control-inline .MuiTextField-root {
  flex: 1;
}

.billing-container h1.page-title {
  font-size: 2rem;
  margin-bottom: 2rem;
  font-weight: 600;
  text-align: center;
}

.billing-container .billing-status {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  font-size: 1.2rem;
  font-weight: 500;
}

.billing-container .status-live {
  color: #38B4BA;
}

.billing-container .status-inactive {
  color: #E11770;
}

.billing-container .add-card-section {
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.billing-container .billing-history,
.billing-container .subscription-management {
  margin-top: 3rem;
}

.billing-container .billing-history h3,
.billing-container .subscription-management h3 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 600;
}

.billing-container .billing-history table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
}

.billing-container .billing-history th,
.billing-container .billing-history td {
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #adadad;
}

.billing-container .subscription-buttons {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
}

.billing-container button {
  padding: 0.75rem 1.5rem;
  margin: 0 5px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}



.dashboard-button {

  /* Adjust as needed */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  transition: background-color 0.3s ease;
}



.billing-info p.cancel-note {
  font-size: 14px;
  line-height: 21px;
  text-transform: none;
  max-width: 330px;
  margin: 35px auto 10px;
}

.billing-menu {
  width: 200px;
  max-width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  border: 1px solid #d3d3d3;
  border-radius: 10px;
  background-color: white;
  z-index: 1;
  overflow: hidden;
}

.billing-info p.canceled {
  color: #712d93;
  font-weight: 800;
  font-size: 23px;
}

.billing-history-section {
  text-transform: capitalize;
}

.billing-info p.paused {
  color: red;
  font-weight: 800;
  font-size: 23px;
}

.billing-info .active {
  font-size: 23px;
  font-weight: 800;
}

.action-buttons {
  margin: auto;
  display: block;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 11px;
}

.dashboard-container .dashboard-menu {
  width: 200px;
  max-width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  border: 1px solid #adadad;
  border-radius: 10px;
  background-color: white;
  z-index: 1200;
  overflow: hidden;
}

.billing-menu .tab {
  display: block;
  width: 100%;
  padding: 1rem;
  text-decoration: none;
  color: black;
  font-weight: 600;
}

.billing-menu .tab:not(:last-child) {
  border-bottom: 1px solid #adadad;
}

.tab {
  text-decoration: none;
  padding: 10px 20px;
  background-color: #f0f0f0;
  border-radius: 5px;
  color: #333;
  font-size: 16px;
  transition: background-color 0.3s ease, color 0.3s ease;
}




.billing-content {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  max-width: 607px;
  margin-left: auto;
}

.billing-history-section,
.my-plan-section,
.saved-cards-section {
  display: block;
}

.page-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.status-live {
  color: green;
}

.status-inactive {
  color: red;
}

table {
  width: 100%;
  border-collapse: collapse;
}

table th,
table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

table th {
  background-color: #f4f4f4;
}

.plan-status {
  margin-top: 20px;
}

.billing-info {
  text-align: center;
  /* Center align all content */
  font-family: Arial, sans-serif;
  /* Optional: set font */
}

.billing-info h3 {
  font-weight: bold;
  /* Make the title bold */
  font-size: 24px;
  /* Adjust the font size as needed */
}

.billing-info p {
  font-weight: normal;
  /* Keep the paragraph text normal */
  font-size: 18px;
  text-align: center;
  /* Adjust font size for paragraphs */
  margin: 10px 0;

  text-transform: capitalize;
}

.billing-info .active {
  color: green;
  /* Green for active subscription */
}

.billing-info .inactive {
  color: red;
  /* Red for inactive subscription */
}

/* Mobile adjustments */
@media screen and (max-width: 899px) {
  .billing-container {
    width: 100%;
    margin-left: 0;
  }

  .billing-content {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #fff;
    max-width: 100%;
    margin-left: auto;
  }

  .billing-menu {
    width: 100%;
    /* Full width of the screen */
    position: relative;
    /* Allow it to stack with the content */
    top: 0;

    margin-bottom: 1rem;
    /* Space between menu and content */
  }

  .billing-content {
    margin-top: 60px;
    /* Ensure the content is below the fixed menu */
    padding: 10px;
  }


  .billing-menu .tab {
    display: block;
    width: 100%;
    padding: 1rem;
    text-align: center;

    font-weight: 600;
    text-align: center;
  }
}

@media screen and (max-width: 525px) {
  .billing-container {
    padding: 0.5rem;
  }

  .billing-menu .tab {
    display: block;
    width: 100%;
    padding: 1rem;
    text-align: center;

    font-weight: 600;
    text-align: center;
  }

  .billing-container h1.page-title {
    font-size: 1.5rem;
  }

  .billing-container .add-card-section {
    padding: 1rem;
  }
}