.footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    padding: 2rem 1rem;
    flex-wrap: wrap-reverse;
    align-self: center;
    gap: 0.5rem;
}

.footer * {
    font-size: 14px;
    font-weight: 500;
    color: grey;
    margin: 0;
}

.stripelogo {
    max-width: 150px;
    margin: auto;
    text-align: center;
    display: block;
    margin-top: -83px;
}

@media screen and (max-width: 650px) {
    .footer {
        flex-direction: column;
    }
}