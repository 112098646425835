.howitworks-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.howitworks-heading-box {
    text-align: center;
    margin-bottom: 40px;
}

.page-heading h1 {
    font-size: 2.5rem;
    color: #000;
    /* Blue title color */
    margin-bottom: 10px;
}

.howitworks-heading-box svg {
    max-width: 100%;
    position: absolute;
    margin-top: 90px;
}

.howitworks-bottom-box {
    display: flex;
    justify-content: space-between;
}

.howitworks-row {
    display: block;

    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}

.howitworks-left-col,
.howitworks-right-col {
    width: 100%;
}

.howitworks-left-col h4,
.howitworks-right-col h4 {
    font-size: 2rem;
    color: #000;
    /* Blue color for subtitles */
    margin-bottom: 10px;
}

.howitworks-left-col h2,
.howitworks-right-col h2 {
    font-size: 1.5rem;
  
    /* Blue color for titles */
    margin-bottom: 20px;
}

.howitworks-left-col p,
.howitworks-right-col p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 10px;
}

ol {
    margin: 10px 0 20px 20px;
}

li {
    margin-bottom: 10px;
}

strong {
    font-weight: bold;
}

@media (max-width: 800px) {
    .howitworks-row {
        flex-direction: column;
    }

    .howitworks-left-col,
    .howitworks-right-col {
        width: 100%;
    }
}