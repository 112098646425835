.courses-list h6 {
    font-size: 0.9rem !important;
}

.courses-list h5 {
    font-size: 1rem !important;
}

/* Full container */
.courses-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1292px;
    padding: 20px;
    width: 100%;
}

/* Search bar section */
.searchnew-container {
    display: block;
    /* justify-content: center; */
    margin-bottom: 20px;
    width: 100%;
    margin-left: auto;
    max-width: 1004px;
}

/* Main layout: Sidebar + Courses */
.content-container {
    display: flex;
    gap: 20px;
}

/* Sidebar filter */
.filter-container {
    width: 250px;
    flex-shrink: 0;
    position: relative;
    margin-top: -130px;
}

/* Courses list */
.courses-list {
    flex-grow: 1;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .content-container {
        flex-direction: column;
    }

    .filter-container {
        margin-top: 0px;
        width: 100%;
    }

    .courses-list {
        justify-content: center;
    }
}