.profile-container {
    display: block;
}

.progress-bar {
    display: none;
}

.progress-bar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 10px 0;
}

.progress-bar {
    height: 6px;
    background-color: #e0e0e0;
    width: 90%;
    border-radius: 3px;
    overflow: hidden;
    margin-bottom: 15px;
}

.progress-bar-filled {
    height: 100%;
    background-color: #4f1f67;
    transition: width 0.3s ease;
}

.progress-bar-steps {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 600px;
}

.progress-step {
    text-align: center;
    flex: 1;
    position: relative;
}

.step-circle {
    width: 27px;
    height: 27px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #4f1f67;
    margin: 0 auto;
    font-weight: 800;
    border: 2px solid #4f1f67;
}



.completed .step-circle {
    background-color: #e0e0e0;
}

.step-label {
    margin-top: 5px;
    font-size: 10px;
    color: #000000;
    white-space: nowrap;
}

.progress-step.completed .step-label {
    color: #4f1f67;
}

.progress-step:not(:last-child)::after {
    content: "";
    position: absolute;
    top: 28%;
    right: -50%;
    width: 100%;
    height: 2px;
    background-color: #e0e0e0;
    z-index: -1;
    transform: translateY(-50%);
}

.progress-step.completed:not(:last-child)::after {
    background-color: #4f1f67;
}

/* Mobile styles */
@media (max-width: 768px) {
    .progress-bar-steps {
        flex-direction: column;
        align-items: center;
    }

    .progress-step {
        margin-bottom: 10px;
    }

    .progress-step:not(:last-child)::after {
        display: none;
    }
}


.boxy {
    max-width: 600px;
    margin: auto;
}